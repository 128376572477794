.list {
  width: 100%;  
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.item {
  padding: 0 8px 10px 0;
}

.item:nth-child(7), .item:nth-child(8) {
  padding-bottom: 0;
}
