@import '../../scss/_variables.scss';

:global(.InfoBox__container) {
  position: relative;
  display: flex;
  font-size: 13px;
  line-height: 20px;
  width: calc(100% + 20px);
  margin: 15px 0 0 auto;
  padding: 21px 15px 21px 18px;
  border-radius: $commonRadius;
  background-color: $sidebarBackground;
  color: $darkGray;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    background-color: $mainBlue;
    height: 100%;
  }
}

:global(.infobox__content--row) {
  width: 100%;
  display: flex;
  max-width: 495px;
}

:global(.infobox__text--default) {
  color: $darkGray;
  width: 33%;
}

:global(.infobox__text--highlighted) {
  color: $textColour;
  width: 67%;
  word-break: break-word;
}

.content {
  width: 100%;
  display: flex;
  max-width: 495px;
}

.column {
  composes: content;
  flex-direction: column;
}

.row {
  composes: content;
}

.highlighed {
  composes: content;
  flex-direction: column;
  color: $textColour;

  &>svg {
    max-height: 47px;
    max-width: 110px;
    width: 100%;
  }
}

.column {
  .highlighed>svg {
    margin-top: 15px;
  }
}

.row {
  .highlighed {
    margin-left: 15px;
  }
}

.text {
  margin: 0;
}


@media (min-width: 360px) {
  :global(.InfoBox__container) {
    font-size: 14px;
    padding: 21px 35px 21px 18px;
  }
}

@media (min-width: 640px) { 
  :global(.InfoBox__container) {
    //calc: maxContentWidth: 505px + one margin (auto): (100% - 505px)/2) -> ((100%-505px)/2 + 505px)
    width: calc(50% + 272.5px);
    right: -20px;
  }

  :global(.InfoBox__container.containerCenter) {
    right: 0;
  }
}

@media (min-width: 1240px) { 
  :global(.InfoBox__container) {
    width: calc(100% + 20px);
    right: 0px;
  }

  :global(.InfoBox__column--first) {
    width: unset;
  }
}

