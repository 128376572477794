@import '../../../scss/_variables.scss';
@import '../../../scss/_mixin.scss';

.container {
  width: 100%;
  margin: 0 auto;
  background-color: $summaryBackground;
  padding: 31px 19px 31px 19px;
  font-size: 14px;
  font-weight: normal;
  @include border-radius('topRight', $sidebarRadius);
  @include border-radius('topLeft', $sidebarRadius);
}

.textSize, .textPrice {
  padding: 0 5px;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.textSize {
  flex-direction: column;
  padding-bottom: 10px;
  line-height: 23px;
}

.textPrice {
  color: $darkGray;
  line-height: 30px;
}

.highligted {
  color: $mainBlue;
  font-weight: 600;
  font-size: 18px;
}

.container.alert {
  padding: 0;

  .textSize {
    color: $alertColour;
    padding: 30px 24px 24px 24px;
  }

  .textAlert {
    line-height: 20px;
    color: $white;
    background-color: $alertBackground;
    padding: 20px 27px 30px 21px;

    & > p {
      margin: 0;
    }
  }
}

.content {
  max-width: $maxContentWidth;
  margin: 0 auto;
}

@media (min-width: 380px) {
  .textSize {
    flex-direction: row;
    padding-bottom: 0px;
    line-height: 30px;
  }
};

@media (min-width: 613px) {
  .container.alert {
    .content {
      max-width: unset;
    }
    .textSize, .textAlert p {
      max-width: $maxContentWidth;
      margin: 0 auto;
    }
  }

  .textAlert p {
    padding: 0 24px;
  }
}

@media (min-width: 768px) { 
  .container{
    width: 93%;
  }
}

@media (min-width: 1240px) { 
  .container, .textAlert {
    width: 100%;
    @include border-radius('bottomRight', $sidebarRadius);
    @include border-radius('bottomLeft', $sidebarRadius); 
  }

  .content {
    width: 100%;
  }

  .textAlert p {
    padding: 0;
  }
}