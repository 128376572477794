* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  outline-color: transparent;
}

p {
  margin: 0;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(/static/media/Poppins-Regular.dd1aed50.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(/static/media/Poppins-SemiBold.5bb31283.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(/static/media/Poppins-Bold.922cb3bc.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Amatic';
  src: url(/static/media/AmaticSC.bd0086ce.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'cmu';
  src: url(/static/media/cmu-typewriter.3d829772.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Havana';
  src: url(/static/media/Havana.fd653e3e.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Pecita';
  src: url(/static/media/Pecita.3ad4df08.woff2) format('woff2'),
        url(/static/media/Pecita.befbe107.woff) format('woff'), 
        url(/static/media/Pecita.2463928f.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url(/static/media/Quicksand.5d51b01f.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Shadows';
  src: url(/static/media/Shadows.d35cac4b.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'LearningCurve';
  src: url(/static/media/LearningCurve.76097af9.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Tamoro';
  src: url(/static/media/Tamoro.6e8ffb37.ttf) format('truetype');
  font-weight: normal;
}
.App {
  background-color: #fff;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  color: #1f2227; }

@media (min-width: 1240px) {
  .App {
    height: 100vh; } }

input[type], textarea {
  -webkit-appearance: none;
          appearance: none; }

input[type='text']:focus,
input[type='number']:focus,
textarea:focus {
  font-size: 14px; }

/* disable auto-zoom on iphone input field focus */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 14px; } }

.Stage_container__2Cku_ {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: relative;
  z-index: 0;
  background-color: #f6f6f6; }

@media (min-width: 1240px) {
  .Stage_container__2Cku_ {
    -webkit-flex-direction: row;
            flex-direction: row;
    overflow-x: hidden; } }

.Background_container__O6VN- {
  position: relative;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px; }

.Background_backgroundImage__32PcV {
  position: absolute;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 70%;
  background-image: url(/static/media/bg_livingroom.351d5f0f.jpg); }

.backgroundImage-enter {
  opacity: 0.01; }

.backgroundImage-enter.backgroundImage-enter-active {
  opacity: 1;
  transition: opacity .5s ease-in-out; }

.backgroundImage-leave {
  opacity: 1; }

.backgroundImage-leave.backgroundImage-leave-active {
  opacity: 0.01;
  transition: opacity .5s ease-in-out; }

.Background_wrapper__2lZ3F {
  height: 87vh;
  color: white; }

.Background_svgCable__MN2Df {
  position: absolute;
  height: 100%;
  width: 1%;
  z-index: 0;
  top: 0;
  left: 47%;
  padding-top: 28vh;
  padding-bottom: 37vh;
  display: -webkit-flex;
  display: flex; }

#bg_cable {
  height: 100%;
  top: 0;
  padding-top: inherit;
  padding-bottom: inherit; }

.Background_svgCable_white__bvZ7X .Background_svgCableDefault__iX83f > #bg_cable g path {
  fill: #e0e0e0;
  fill-opacity: 0.6; }

.Background_svgCableDefault__iX83f {
  height: 98%;
  -webkit-transform: translate(-43%, 2%);
          transform: translate(-43%, 2%); }
  .Background_svgCableDefault__iX83f #bg_cable {
    height: 100%; }
  .Background_svgCableDefault__iX83f #bg_cable g path {
    fill-opacity: 1;
    fill: #383b3f; }

.Background_svgCableShadow__1brej {
  height: 98%;
  -webkit-transform: translate(7%, 3%);
          transform: translate(7%, 3%); }
  .Background_svgCableShadow__1brej #bg_cable {
    height: 100%; }
  .Background_svgCableShadow__1brej #bg_cable g path {
    fill-opacity: 0.3;
    fill: #383b3f; }

.Background_svgCable_livingroom__2iLBK #bg_cable {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear; }

.Background_svgCable_bedroom__3740t #bg_cable {
  -webkit-transform-origin: left 0px;
          transform-origin: left 0px;
  -webkit-transform: scaleY(0.81);
          transform: scaleY(0.81);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear; }

.Background_svgCable_kidsroom__1k3qd #bg_cable {
  -webkit-transform-origin: left -15px;
          transform-origin: left -15px;
  -webkit-transform: scaleY(1.3);
          transform: scaleY(1.3);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear; }

.Background_overlay__1Uh-h {
  position: absolute;
  width: 100%;
  height: 100%; }
  .Background_overlay__1Uh-h::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); }

.Background_lightButton__w8e3v {
  position: absolute;
  top: 0;
  right: 0;
  margin: 24px 20px 0 0;
  width: 16px;
  height: 20px; }

.Background_list__3TczM {
  height: 100%;
  margin: 0 auto;
  padding: 0;
  padding-bottom: 18px;
  font-size: 15px;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-end;
          align-items: flex-end; }

.Background_item__3cKSf {
  padding: 0px 15px;
  cursor: pointer;
  position: relative;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: -webkit-transform ease-in-out .2s;
  transition: transform ease-in-out .2s;
  transition: transform ease-in-out .2s, -webkit-transform ease-in-out .2s; }
  .Background_item__3cKSf:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }

.Background_active__2pHbD {
  top: 0px; }
  .Background_active__2pHbD::after {
    content: '';
    position: absolute;
    background-color: #f6f6f6;
    bottom: -30px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 21px;
    height: 21px;
    border-radius: 50%; }
  .Background_active__2pHbD:hover {
    -webkit-transform: none;
            transform: none; }

@media (min-width: 640px) {
  .Background_svgCable__MN2Df {
    left: 55%;
    -webkit-transform: translateX(-55%);
            transform: translateX(-55%); } }

@media (min-width: 768px) {
  .Background_container__O6VN- {
    background-position-x: 70%; }
  .Background_wrapper__2lZ3F {
    height: 90vh; }
  .Background_svgCable__MN2Df {
    padding-bottom: 38vh;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .Background_svgCable_livingroom__2iLBK #bg_cable {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    transition: -webkit-transform 0.2s linear;
    transition: transform 0.2s linear;
    transition: transform 0.2s linear, -webkit-transform 0.2s linear; }
  .Background_svgCable_bedroom__3740t #bg_cable {
    -webkit-transform-origin: left -10px;
            transform-origin: left -10px;
    -webkit-transform: scaleY(0.82);
            transform: scaleY(0.82);
    transition: -webkit-transform 0.2s linear;
    transition: transform 0.2s linear;
    transition: transform 0.2s linear, -webkit-transform 0.2s linear; }
  .Background_svgCable_kidsroom__1k3qd #bg_cable {
    -webkit-transform-origin: left 15px;
            transform-origin: left 15px;
    -webkit-transform: scaleY(1.32);
            transform: scaleY(1.32);
    transition: -webkit-transform 0.2s linear;
    transition: transform 0.2s linear;
    transition: transform 0.2s linear, -webkit-transform 0.2s linear; }
  .Background_lightButton__w8e3v {
    margin: 28px 35px 0 0;
    width: 100px;
    height: 50px; } }

@media (min-width: 1024px) {
  .Background_svgCable__MN2Df {
    left: 55%;
    -webkit-transform: translateX(-55%);
            transform: translateX(-55%); } }

@media (min-width: 1240px) {
  .Background_container__O6VN- {
    background-position-x: 55%;
    background-size: cover;
    min-height: 755px;
    position: absolute;
    margin-bottom: 0;
    border-radius: unset; }
  .Background_wrapper__2lZ3F {
    position: relative;
    top: 0;
    height: 100%; }
  .Background_list__3TczM {
    left: 440px;
    width: calc(100% - 440px);
    margin: 0 0 0 auto;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding-top: 50px; }
  .Background_item__3cKSf {
    padding: 10px 15px; }
  .Background_active__2pHbD::after {
    content: '';
    background-color: #fff; }
  .Background_lightButton__w8e3v {
    margin-right: 4%; }
  .Background_svgCable__MN2Df {
    padding-bottom: 42.6vh;
    left: 62%;
    -webkit-transform: translateX(-62%);
            transform: translateX(-62%); }
  .Background_svgCable_livingroom__2iLBK #bg_cable {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    transition: -webkit-transform 0.2s linear;
    transition: transform 0.2s linear;
    transition: transform 0.2s linear, -webkit-transform 0.2s linear; }
  .Background_svgCable_bedroom__3740t #bg_cable {
    -webkit-transform-origin: left 20px;
            transform-origin: left 20px;
    -webkit-transform: scaleY(0.83);
            transform: scaleY(0.83);
    transition: -webkit-transform 0.2s linear;
    transition: transform 0.2s linear;
    transition: transform 0.2s linear, -webkit-transform 0.2s linear; }
  .Background_svgCable_kidsroom__1k3qd #bg_cable {
    -webkit-transform-origin: left -35px;
            transform-origin: left -35px;
    -webkit-transform: scaleY(1.23);
            transform: scaleY(1.23);
    transition: -webkit-transform 0.2s linear;
    transition: transform 0.2s linear;
    transition: transform 0.2s linear, -webkit-transform 0.2s linear; }
  .Background_active__2pHbD {
    top: -25px; }
    .Background_active__2pHbD::after {
      content: '';
      position: absolute;
      background-color: #fff;
      bottom: -20px;
      left: 45.3%;
      width: 9px;
      height: 9px;
      border-radius: 50%; } }

@media (min-width: 1440px) {
  .Background_svgCable__MN2Df {
    left: 60%;
    -webkit-transform: translateX(-60%);
            transform: translateX(-60%); }
  .Background_lightButton__w8e3v {
    margin-right: 6%; } }

@media (min-width: 1680px) {
  .Background_svgCable__MN2Df {
    padding-bottom: 41.8vh; } }

.LightSwitcher_container__3b8nO {
  background-color: transparent;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  border: 0 transparent;
  padding: 0;
  margin: 0 0 0 auto;
  text-align: center;
  max-height: 52px;
  outline-color: transparent;
  outline-style: none;
  cursor: pointer; }
  .LightSwitcher_container__3b8nO > svg {
    max-width: 25px;
    width: 100%; }
  .LightSwitcher_container__3b8nO svg {
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: -webkit-transform ease-in-out .2s;
    transition: transform ease-in-out .2s;
    transition: transform ease-in-out .2s, -webkit-transform ease-in-out .2s; }
  .LightSwitcher_container__3b8nO .LightSwitcher_description__2vicY {
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: -webkit-transform ease-in-out .2s;
    transition: transform ease-in-out .2s;
    transition: transform ease-in-out .2s, -webkit-transform ease-in-out .2s; }

.LightSwitcher_container__3b8nO:hover svg {
  -webkit-transform: scale(1.05);
          transform: scale(1.05); }

.LightSwitcher_container__3b8nO:hover .LightSwitcher_description__2vicY {
  -webkit-transform: scale(1.05);
          transform: scale(1.05); }

.LightSwitcher_description__2vicY {
  display: none;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 10px;
  font-size: 15px; }

@media (min-width: 768px) {
  .LightSwitcher_container__3b8nO {
    width: 90px; }
    .LightSwitcher_container__3b8nO > svg {
      width: 21px;
      height: 25px; }
  .LightSwitcher_description__2vicY {
    display: inline; } }

.Header_container__spGvv {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  margin: 17px 0 0 55px;
  width: 42px;
  height: 35px; }

.Header_heading__3o-oK {
  margin: 0; }

.Header_logo__30sb- {
  position: relative;
  max-width: 100%; }
  .Header_logo__30sb-::before {
    content: '';
    position: absolute;
    color: #fff;
    border: 2px solid #fff;
    border-width: 0 0 3px 3px;
    width: 12px;
    height: 12px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    left: -30px;
    top: 10px; }
  .Header_logo__30sb-:focus {
    color: inherit;
    outline-color: transparent; }
  .Header_logo__30sb-:active {
    color: inherit;
    text-decoration: none; }

.Header_logoImage__1yOZI {
  max-width: 55px; }

@media (min-width: 768px) {
  .Header_container__spGvv {
    margin: 19px 0 15px 128px;
    width: 55px;
    height: 46px; }
  .Header_logo__30sb-::before {
    width: 18px;
    height: 18px;
    left: -50px;
    top: 2px; } }

.Preview_container__2ljSm {
  height: 74%;
  width: 100%;
  -webkit-order: 0;
          order: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  overflow-y: hidden;
  -webkit-transform: translate3D(0, 0, 0);
  transform: translate3D(0, 0, 0); }

.Preview_text__3nN6f {
  max-width: 94%;
  max-height: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  -webkit-transform: translateY(17vh);
          transform: translateY(17vh);
  overflow-wrap: break-word;
  text-align: center;
  color: #fff;
  padding: 10px;
  line-height: 44px; }

#Preview_neon__1aGjy {
  max-width: 100%; }

.Preview_classicRed__3EV1k, .Preview_classicBlue__3lkOI {
  color: rgba(255, 255, 255, 0.5); }

.Preview_superOrange__1ZznW {
  color: #F96929; }

.Preview_novialGold__1mAvD {
  color: #F6EB47; }

.Preview_emeraldGreen__1yRwX {
  color: #20893E; }

.Preview_cobaltBlue__3tBNG {
  color: #2917A6; }

.Preview_rubyRed__1rNMC {
  color: #CF241B; }

.Preview_small__3L00L {
  font-size: 23px; }

.Preview_big__17Gad {
  font-size: 35px; }

.Preview_cloud__J_Jbi {
  border-radius: 60px; }

.Preview_acrylic__1TbOM {
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.6), 1px 1px 1px rgba(255, 255, 255, 0.4) inset, -1px -1px 1px rgba(255, 255, 255, 0.2) inset, 10px 10px 10px rgba(0, 0, 0, 0.1) inset, -10px -10px 10px rgba(0, 0, 0, 0.1) inset;
  background: linear-gradient(1deg, rgba(255, 255, 255, 0) 43%, rgba(255, 255, 255, 0.11) 45%); }
  .Preview_acrylic_transparent__xN8ao {
    background-color: transparent; }
  .Preview_acrylic_white__3b64f {
    background-color: rgba(255, 255, 255, 0.75); }
  .Preview_acrylic_black__Xf2b8 {
    background-color: rgba(0, 0, 0, 0.75); }

.Preview_aluminium__2Blqs {
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.6), 1px 1px 1px rgba(255, 255, 255, 0.4) inset, -1px -1px 1px rgba(255, 255, 255, 0.2) inset, 10px 10px 30px rgba(0, 0, 0, 0.1) inset, -10px -10px 30px rgba(0, 0, 0, 0.1) inset; }
  .Preview_aluminium_alWhite__FBDO6 {
    background-color: #F6F6F6; }
  .Preview_aluminium_alBlack__3HcQg {
    background-color: #0A0A0A; }
  .Preview_aluminium_alPure__175NI {
    background-color: #8F8F8F; }
  .Preview_aluminium_alYellow__m4Mup {
    background-color: #FAD201; }
  .Preview_aluminium_alRed__oyxRA {
    background-color: #CC0605; }
  .Preview_aluminium_alBlue__Kekh4 {
    background-color: #2270B3; }
  .Preview_aluminium_alGreen__3jT0D {
    background-color: #57A63A; }
  .Preview_aluminium_alPink__Cyu8S {
    background-color: #D82E6C; }
  .Preview_aluminium_alGray__3aPAp {
    background-color: #2A3133; }
  .Preview_aluminium_alRaw__2ZgQp {
    background-color: #A5A5A5; }

.Preview_wood__36_hS {
  background-image: url(/static/media/textures_wood.7ddb8e41.png);
  background-size: 145%; }

.Preview_noLight__2xO6d .Preview_classicRed__3EV1k {
  color: #FCC375;
  text-shadow: 0 0 1px #F8110B, 0 0 30px #F8110B, 3px 3px 30px rgba(0, 0, 0, 0.05), 0 0 8px #F8110B, 0 0 15px #F8110B, 0 0 30px #F8110B, 0 0 50px #F8110B, 0 0 50px #F8110B, 0 0 80px #F8110B, 0 0 200px #F8110B, 0 0 250px #F8110B; }

.Preview_noLight__2xO6d .Preview_classicBlue__3lkOI {
  color: #B0F2FE;
  text-shadow: 0 0 1px #2594BF, 0 0 30px #2594BF, 3px 3px 30px rgba(0, 0, 0, 0.05), 0 0 8px #2594BF, 0 0 15px #2594BF, 0 0 30px #2594BF, 0 0 50px #2594BF, 0 0 50px #2594BF, 0 0 80px #2594BF, 0 0 200px #2594BF, 0 0 250px #2594BF; }

.Preview_noLight__2xO6d .Preview_superBlue__33tUb {
  color: #fff;
  text-shadow: 0 0 1px #74A8FE, 0 0 30px #74A8FE, 3px 3px 30px rgba(0, 0, 0, 0.05), 0 0 8px #74A8FE, 0 0 15px #74A8FE, 0 0 30px #74A8FE, 0 0 50px #74A8FE, 0 0 50px #74A8FE, 0 0 80px #74A8FE, 0 0 200px #74A8FE, 0 0 250px #74A8FE; }

.Preview_noLight__2xO6d .Preview_superGreen__3QPIB {
  color: #fff;
  text-shadow: 0 0 1px #43FE9C, 0 0 30px #43FE9C, 3px 3px 30px rgba(0, 0, 0, 0.05), 0 0 8px #43FE9C, 0 0 15px #43FE9C, 0 0 30px #43FE9C, 0 0 50px #43FE9C, 0 0 50px #43FE9C, 0 0 80px #43FE9C, 0 0 200px #43FE9C, 0 0 250px #43FE9C; }

.Preview_noLight__2xO6d .Preview_superPink__1d5B6 {
  color: #fff;
  text-shadow: 0 0 1px #FA88AA, 0 0 30px #FA88AA, 3px 3px 30px rgba(0, 0, 0, 0.05), 0 0 8px #FA88AA, 0 0 15px #FA88AA, 0 0 30px #FA88AA, 0 0 50px #FA88AA, 0 0 50px #FA88AA, 0 0 80px #FA88AA, 0 0 200px #FA88AA, 0 0 250px #FA88AA; }

.Preview_noLight__2xO6d .Preview_superPurple__16aAJ {
  color: #fff;
  text-shadow: 0 0 1px #FA8BEA, 0 0 30px #FA8BEA, 3px 3px 30px rgba(0, 0, 0, 0.05), 0 0 8px #FA8BEA, 0 0 15px #FA8BEA, 0 0 30px #FA8BEA, 0 0 50px #FA8BEA, 0 0 50px #FA8BEA, 0 0 80px #FA8BEA, 0 0 200px #FA8BEA, 0 0 250px #FA8BEA; }

.Preview_noLight__2xO6d .Preview_superOrange__1ZznW {
  color: #FEEF33;
  text-shadow: 0 0 1px #F96929, 0 0 30px #F96929, 3px 3px 30px rgba(0, 0, 0, 0.05), 0 0 8px #F96929, 0 0 15px #F96929, 0 0 30px #F96929, 0 0 50px #F96929, 0 0 50px #F96929, 0 0 80px #F96929, 0 0 200px #F96929, 0 0 250px #F96929; }

.Preview_noLight__2xO6d .Preview_warmWhite__3crzl {
  color: #fff;
  text-shadow: 0 0 1px #FEF3E5, 0 0 30px #FEF3E5, 3px 3px 30px rgba(0, 0, 0, 0.05), 0 0 8px #FEF3E5, 0 0 15px #FEF3E5, 0 0 30px #FEF3E5, 0 0 50px #FEF3E5, 0 0 50px #FEF3E5, 0 0 80px #FEF3E5, 0 0 200px #FEF3E5, 0 0 250px #FEF3E5; }

.Preview_noLight__2xO6d .Preview_coldWhite__l9r9- {
  color: #fff;
  text-shadow: 0 0 1px #F5FCFE, 0 0 30px #F5FCFE, 3px 3px 30px rgba(0, 0, 0, 0.05), 0 0 8px #F5FCFE, 0 0 15px #F5FCFE, 0 0 30px #F5FCFE, 0 0 50px #F5FCFE, 0 0 50px #F5FCFE, 0 0 80px #F5FCFE, 0 0 200px #F5FCFE, 0 0 250px #F5FCFE; }

.Preview_noLight__2xO6d .Preview_novialGold__1mAvD {
  color: #fff;
  text-shadow: 0 0 1px #FBAD00, 0 0 30px #FBAD00, 3px 3px 30px rgba(0, 0, 0, 0.05), 0 0 8px #FBAD00, 0 0 15px #FBAD00, 0 0 30px #FBAD00, 0 0 50px #FBAD00, 0 0 50px #FBAD00, 0 0 80px #FBAD00, 0 0 200px #FBAD00, 0 0 250px #FBAD00; }

.Preview_noLight__2xO6d .Preview_emeraldGreen__1yRwX {
  color: #fff;
  text-shadow: 0 0 1px #43FD2B, 0 0 30px #43FD2B, 3px 3px 30px rgba(0, 0, 0, 0.05), 0 0 8px #43FD2B, 0 0 15px #43FD2B, 0 0 30px #43FD2B, 0 0 50px #43FD2B, 0 0 50px #43FD2B, 0 0 80px #43FD2B, 0 0 200px #43FD2B, 0 0 250px #43FD2B; }

.Preview_noLight__2xO6d .Preview_turqouise__37L3Z {
  color: #fff;
  text-shadow: 0 0 1px #42F7FE, 0 0 30px #42F7FE, 3px 3px 30px rgba(0, 0, 0, 0.05), 0 0 8px #42F7FE, 0 0 15px #42F7FE, 0 0 30px #42F7FE, 0 0 50px #42F7FE, 0 0 50px #42F7FE, 0 0 80px #42F7FE, 0 0 200px #42F7FE, 0 0 250px #42F7FE; }

.Preview_noLight__2xO6d .Preview_cobaltBlue__3tBNG {
  color: #fff;
  text-shadow: 0 0 1px #132AFF, 0 0 30px #132AFF, 3px 3px 30px rgba(0, 0, 0, 0.05), 0 0 8px #132AFF, 0 0 15px #132AFF, 0 0 30px #132AFF, 0 0 50px #132AFF, 0 0 50px #132AFF, 0 0 80px #132AFF, 0 0 200px #132AFF, 0 0 250px #132AFF; }

.Preview_noLight__2xO6d .Preview_rubyRed__1rNMC {
  color: #F94659;
  text-shadow: 0 0 1px #CF241B, 0 0 30px #CF241B, 3px 3px 30px rgba(0, 0, 0, 0.05), 0 0 8px #CF241B, 0 0 15px #CF241B, 0 0 30px #CF241B, 0 0 50px #CF241B, 0 0 50px #CF241B, 0 0 80px #CF241B, 0 0 200px #CF241B, 0 0 250px #CF241B; }

@media (min-width: 640px) {
  .Preview_container__2ljSm {
    height: 74vh; }
  .Preview_text__3nN6f {
    -webkit-transform: translate(10%, 11vh);
            transform: translate(10%, 11vh);
    max-width: 70%; } }

@media (min-width: 768px) {
  .Preview_container__2ljSm {
    height: 78vh; }
  .Preview_text__3nN6f {
    max-width: 94%;
    -webkit-transform: translateY(18vh);
            transform: translateY(18vh);
    line-height: 50px;
    padding: 20px; }
  .Preview_small__3L00L {
    font-size: 33px; }
  .Preview_big__17Gad {
    font-size: 50px;
    line-height: 64px; } }

@media (min-width: 1024px) {
  .Preview_text__3nN6f {
    top: 0;
    left: 57%;
    -webkit-transform: translate(-55%, 14vh);
            transform: translate(-55%, 14vh); } }

@media (min-width: 1240px) {
  .Preview_container__2ljSm {
    height: 98%; }
  .Preview_text__3nN6f {
    max-width: 45%;
    left: 64%;
    -webkit-transform: translate(-65%, 20vh);
            transform: translate(-65%, 20vh); } }

@media (min-width: 1440px) {
  .Preview_text__3nN6f {
    left: 61%;
    -webkit-transform: translate(-62%, 20vh);
            transform: translate(-62%, 20vh); } }

.SidebarTabs_container__1KTww {
  background-color: #bbbbbb;
  width: 100%;
  height: 53px;
  border-radius: 26.5px;
  margin: 15px 0; }

.SidebarTabs_item__3CZfy {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
  height: 31px;
  max-width: 37px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }

.SidebarTabs_list__3e0Cm {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  list-style: none;
  height: 100%;
  padding: 12px 0; }

.SidebarTabs_item__3CZfy svg {
  height: 31px;
  max-width: 100%; }

.SidebarTabs_item__3CZfy .tabicon {
  fill: #fff; }

.SidebarTabs_item__3CZfy:hover .tabicon {
  fill: #1a9fdf;
  transition: fill ease-in-out .5s; }

.SidebarTabs_active__H3iec .tabicon {
  fill: #1a9fdf; }

@media (min-width: 768px) {
  .SidebarTabs_container__1KTww {
    width: 93%;
    margin: 15px auto; }
  .SidebarTabs_list__3e0Cm {
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly; } }

@media (min-width: 1240px) {
  .SidebarTabs_container__1KTww {
    background-color: #f6f6f6;
    width: 79px;
    height: unset;
    border-radius: 0;
    margin: 0;
    border-top-left-radius: 20px; }
  .SidebarTabs_list__3e0Cm {
    -webkit-flex-direction: column;
            flex-direction: column;
    width: inherit;
    padding: 35px 0 25px 0; }
  .SidebarTabs_item__3CZfy {
    box-sizing: content-box;
    display: block;
    padding: 20px 10px;
    margin: 0 auto; }
  .SidebarTabs_item__3CZfy .tabicon {
    fill: #818793; }
  .SidebarTabs_active__H3iec .tabicon {
    fill: #1a9fdf; } }

.InfoBox__container {
  position: relative;
  display: -webkit-flex;
  display: flex;
  font-size: 13px;
  line-height: 20px;
  width: calc(100% + 20px);
  margin: 15px 0 0 auto;
  padding: 21px 15px 21px 18px;
  border-radius: 5px;
  background-color: #f6f6f6;
  color: #818793;
  overflow: hidden; }
  .InfoBox__container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    background-color: #1a9fdf;
    height: 100%; }

.infobox__content--row {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  max-width: 495px; }

.infobox__text--default {
  color: #818793;
  width: 33%; }

.infobox__text--highlighted {
  color: #1f2227;
  width: 67%;
  word-break: break-word; }

.InfoBox_content__3iHnO {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  max-width: 495px; }

.InfoBox_column__elgLT {
  -webkit-flex-direction: column;
          flex-direction: column; }

.InfoBox_row__2XJEJ { }

.InfoBox_highlighed__-y36y {
  -webkit-flex-direction: column;
          flex-direction: column;
  color: #1f2227; }
  .InfoBox_highlighed__-y36y > svg {
    max-height: 47px;
    max-width: 110px;
    width: 100%; }

.InfoBox_column__elgLT .InfoBox_highlighed__-y36y > svg {
  margin-top: 15px; }

.InfoBox_row__2XJEJ .InfoBox_highlighed__-y36y {
  margin-left: 15px; }

.InfoBox_text__1narx {
  margin: 0; }

@media (min-width: 360px) {
  .InfoBox__container {
    font-size: 14px;
    padding: 21px 35px 21px 18px; } }

@media (min-width: 640px) {
  .InfoBox__container {
    width: calc(50% + 272.5px);
    right: -20px; }
  .InfoBox__container.containerCenter {
    right: 0; } }

@media (min-width: 1240px) {
  .InfoBox__container {
    width: calc(100% + 20px);
    right: 0px; }
  .InfoBox__column--first {
    width: unset; } }

.InputText_input__tETxH {
  font-family: 'Poppins';
  color: #1f2227;
  width: 92%;
  resize: none;
  font-size: 14px;
  border: 2px solid #e0e0e0;
  border-radius: 5px;
  padding: 20px 10px 10px 10px;
  outline-style: none; }
  .InputText_input__tETxH:focus, .InputText_input__tETxH:active {
    border: 2px solid rgba(26, 159, 223, 0.7);
    outline-color: transparent; }

.InputText_input__tETxH::-webkit-input-placeholder {
  font-family: 'Poppins';
  line-height: 20px;
  color: rgba(31, 34, 39, 0.7); }

.InputText_input__tETxH::placeholder, .InputText_input__tETxH::-webkit-input-placeholder, .InputText_input__tETxH::-moz-placeholder, .InputText_input__tETxH:-ms-input-placeholder {
  font-family: 'Poppins';
  line-height: 20px;
  color: rgba(31, 34, 39, 0.7); }

.InputText_description__s7lKc {
  font-size: 12px;
  color: #818793;
  letter-spacing: 0.7px;
  margin: 0 0 96px 0;
  white-space: pre-line; }

.ButtonText_default__1qYwO {
  cursor: pointer;
  outline: transparent;
  border-radius: 5px;
  border: solid 2px #e0e0e0;
  background-color: transparent;
  height: 85px;
  width: 112px; }

.ButtonText_default__1qYwO:hover {
  box-shadow: -1px 1.7px 10px 0 rgba(37, 37, 37, 0.25);
  transition: box-shadow ease-in-out .3s; }

.ButtonText_selected__1ESzm {
  box-shadow: -1px 1.7px 10px 0 rgba(37, 37, 37, 0.25); }

.ButtonText_font__1BX3x {
  font-size: 18px;
  letter-spacing: 1.8px; }

.ButtonText_size__AXv9Y {
  font-size: 16px;
  font-weight: 600; }

@media (min-width: 365px) {
  .ButtonText_font__1BX3x {
    width: 135px;
    font-size: 19px; } }

.TextFont_list__36zgJ {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 0; }

.TextFont_item__2059F {
  padding: 0 8px 10px 0; }

.TextFont_item__2059F:nth-child(7), .TextFont_item__2059F:nth-child(8) {
  padding-bottom: 0; }

.TextSize_list__374Bn {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 0 0 48px 0; }

.TextSize_item__1d0KO {
  padding-right: 10px; }

.ButtonColour_default__RK0TB {
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  outline: none;
  border-radius: 5px;
  border: 0 transparent;
  width: 40px;
  height: 40px; }

.ButtonColour_default__RK0TB:hover {
  box-shadow: -1px 1.7px 6px 0px rgba(37, 37, 37, 0.4);
  transition: box-shadow ease-in-out .3s; }

.ButtonColour_selected__3H6Xc {
  box-shadow: -1px 1.7px 8px 0px rgba(37, 37, 37, 0.5); }

.ButtonColour_colour__34dgQ, .ButtonColour_cable__1Ted8, .ButtonColour_acrylic__1EaRo, .ButtonColour_aluminium__23t-y, .ButtonColour_wood__2ItQJ { }

.ButtonColour_colour__34dgQ.warmWhite {
  background-color: #FEF3E5;
  border: solid 2px #e0e0e0; }

.ButtonColour_colour__34dgQ.coldWhite {
  background-color: #F5FCFE;
  border: solid 2px #e0e0e0; }

.ButtonColour_cable__1Ted8.transparent, .ButtonColour_acrylic__1EaRo.transparent, .ButtonColour_aluminium__23t-y.transparent {
  background-color: #efefef;
  border: solid 2px #e0e0e0; }

.ButtonColour_cable__1Ted8.white, .ButtonColour_acrylic__1EaRo.white, .ButtonColour_aluminium__23t-y.white {
  background-color: #fff;
  border: solid 2px #e0e0e0; }

.ButtonColour_cable__1Ted8.alWhite, .ButtonColour_acrylic__1EaRo.alWhite, .ButtonColour_aluminium__23t-y.alWhite {
  background-color: #F6F6F6;
  border: solid 2px #e0e0e0; }



.TextColour_list__2T10q {
  width: 100%;
  max-width: 325px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 48px; }

.TextColour_item__1Hin3 {
  padding: 5px; }

.TextColour_item__1Hin3:nth-child(5n + 1) {
  padding-left: 0; }

.TextColour_item__1Hin3:nth-child(5n) {
  padding-right: 0; }

@media (min-width: 360px) {
  .TextColour_item__1Hin3 {
    padding: 10px; } }

@media (min-width: 520px) {
  .TextColour_list__2T10q {
    max-width: 425px; }
  .TextColour_item__1Hin3:nth-child(n) {
    padding: 10px; }
  .TextColour_item__1Hin3:nth-child(7n + 1) {
    padding-left: 0; } }

@media (min-width: 1240px) {
  .TextColour_item__1Hin3:nth-child(n) {
    padding: 10px; }
  .TextColour_item__1Hin3:nth-child(5n + 1) {
    padding-left: 0; } }

.CableColour_list__1Lttd {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.CableColour_item__1__KJ {
  padding-right: 10px; }

.RadioButtonGroup_list__2RltW {
  margin: 0;
  padding-left: 40px; }

.RadioButtonGroup__label {
  position: relative; }

.RadioButtonGroup__item {
  list-style: none;
  margin-bottom: 19px;
  line-height: 19px; }
  .RadioButtonGroup__item input[type=radio]:checked + label:before {
    background-color: #1a9fdf; }

.RadioButtonGroup_input__3YKzr {
  position: absolute;
  top: auto;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE 6/7 */
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  white-space: nowrap;
  outline: transparent; }

.RadioButtonGroup_input__3YKzr, .RadioButtonGroup__label:before {
  content: '';
  background: #fff;
  border-radius: 50%;
  background-color: #e0e0e0;
  border: 5px solid #e0e0e0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 19px;
  height: 19px;
  margin-left: -29px;
  cursor: pointer;
  text-align: center;
  transition: all .1s ease-out; }

.BaseColour_list__21OBp {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 0 0 20px 0;
  max-width: 350px; }

.BaseColour_item__2V7l9 {
  padding: 10px; }

.BaseColour_item__2V7l9:nth-child(4n + 1) {
  padding-left: 15px; }

.BaseColour_item__2V7l9:nth-child(4n) {
  padding-right: 0; }

@media (min-width: 380px) {
  .BaseColour_item__2V7l9:nth-child(n) {
    padding: 10px; }
  .BaseColour_item__2V7l9:nth-child(5n + 1) {
    padding-left: 15px; }
  .BaseColour_item__2V7l9:nth-child(5n) {
    padding-right: 0; } }

@media (min-width: 525px) {
  .BaseColour_list__21OBp {
    max-width: 425px; }
  .BaseColour_item__2V7l9:nth-child(n) {
    padding: 10px; }
  .BaseColour_item__2V7l9:nth-child(7n + 1) {
    padding-left: 15px; } }

@media (min-width: 600px) {
  .BaseColour_list__21OBp {
    max-width: unset; }
  .BaseColour_item__2V7l9:nth-child(n) {
    padding: 10px; }
  .BaseColour_item__2V7l9:nth-child(8n + 1) {
    padding-left: 15px; } }

@media (min-width: 1240px) {
  .BaseColour_item__2V7l9:nth-child(n) {
    padding: 10px; }
  .BaseColour_item__2V7l9:nth-child(5n) {
    padding-right: 0px; } }

.TextBase_description__1Cy8f {
  margin-top: 45px; }

.TextBase_nav__2D7iY {
  padding: 20px 0 37px 0;
  border-top: 1px solid #e0e0e0; }

.TextBase_list__SvPdM {
  font-size: 15px;
  font-weight: normal;
  line-height: 1.33;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100%; }

.TextBase_item__3hr4X {
  cursor: pointer;
  list-style: none;
  color: #818793;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }
  .TextBase_item__3hr4X :hover {
    color: #1a9fdf;
    position: relative; }

.TextBase_heading__oHLnk {
  font-size: inherit;
  font-weight: normal;
  margin: 0; }

.TextBase_active__2wVER {
  position: relative;
  color: #1a9fdf; }
  .TextBase_active__2wVER::after {
    content: '';
    position: absolute;
    width: 142.9%;
    height: 2px;
    left: -22.45%;
    background-color: #1a9fdf;
    bottom: -17px; }

.TextBase_content__1Nh7d .InfoBox__container {
  padding: 25px 21px 21px 21px; }

.ButtonConfirmation_default__3FT5h {
  cursor: pointer;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background-color: #1a9fdf;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  border: 2px solid #1a9fdf;
  outline: #1a9fdf;
  margin-top: 10px; }
  .ButtonConfirmation_default__3FT5h:hover {
    background-color: #157eb1;
    border: 2px solid #157eb1;
    transition: all ease-out .8s; }

.ButtonConfirmation_disabled__2l_qF, .ButtonConfirmation_default__3FT5h:disabled {
  background-color: #989898;
  border: 2px solid #989898;
  outline: #989898;
  cursor: unset; }
  .ButtonConfirmation_disabled__2l_qF:hover, .ButtonConfirmation_default__3FT5h:disabled:hover {
    background-color: #7f7f7f;
    border: 2px solid #7f7f7f; }

.OrderSummary_container__3dSAH {
  width: 100%;
  margin: 0 auto;
  background-color: #e8e7e7;
  padding: 31px 19px 31px 19px;
  font-size: 14px;
  font-weight: normal;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px; }

.OrderSummary_textSize__3LR_c, .OrderSummary_textPrice__2phwx {
  padding: 0 5px;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.OrderSummary_textSize__3LR_c {
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-bottom: 10px;
  line-height: 23px; }

.OrderSummary_textPrice__2phwx {
  color: #818793;
  line-height: 30px; }

.OrderSummary_highligted__3CfuD {
  color: #1a9fdf;
  font-weight: 600;
  font-size: 18px; }

.OrderSummary_container__3dSAH.OrderSummary_alert__nwssQ {
  padding: 0; }
  .OrderSummary_container__3dSAH.OrderSummary_alert__nwssQ .OrderSummary_textSize__3LR_c {
    color: #e50d36;
    padding: 30px 24px 24px 24px; }
  .OrderSummary_container__3dSAH.OrderSummary_alert__nwssQ .OrderSummary_textAlert__2QxYX {
    line-height: 20px;
    color: #fff;
    background-color: #e6838f;
    padding: 20px 27px 30px 21px; }
    .OrderSummary_container__3dSAH.OrderSummary_alert__nwssQ .OrderSummary_textAlert__2QxYX > p {
      margin: 0; }

.OrderSummary_content__3JUb7 {
  max-width: 505px;
  margin: 0 auto; }

@media (min-width: 380px) {
  .OrderSummary_textSize__3LR_c {
    -webkit-flex-direction: row;
            flex-direction: row;
    padding-bottom: 0px;
    line-height: 30px; } }

@media (min-width: 613px) {
  .OrderSummary_container__3dSAH.OrderSummary_alert__nwssQ .OrderSummary_content__3JUb7 {
    max-width: unset; }
  .OrderSummary_container__3dSAH.OrderSummary_alert__nwssQ .OrderSummary_textSize__3LR_c, .OrderSummary_container__3dSAH.OrderSummary_alert__nwssQ .OrderSummary_textAlert__2QxYX p {
    max-width: 505px;
    margin: 0 auto; }
  .OrderSummary_textAlert__2QxYX p {
    padding: 0 24px; } }

@media (min-width: 768px) {
  .OrderSummary_container__3dSAH {
    width: 93%; } }

@media (min-width: 1240px) {
  .OrderSummary_container__3dSAH, .OrderSummary_textAlert__2QxYX {
    width: 100%;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px; }
  .OrderSummary_content__3JUb7 {
    width: 100%; }
  .OrderSummary_textAlert__2QxYX p {
    padding: 0; } }

.FieldHeader_fieldHeading__1bgCa {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
  padding-bottom: 15px; }

.FieldWrapper_fieldWrapper__32gSK {
  margin-bottom: 10px;
  position: relative; }

.FieldWrapper_input__1AMcZ {
  font-family: 'Poppins';
  width: 100%;
  height: 50px;
  padding: 18px 10px;
  border-radius: 5px;
  border: solid 2px rgba(224, 224, 224, 0.6);
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  color: #1f2227; }

.FieldWrapper_input__1AMcZ:focus, .FieldWrapper_input__1AMcZ:active {
  outline-color: transparent;
  outline-style: none;
  border: solid 2px #1a9fdf; }

.FieldWrapper_input__1AMcZ::-webkit-input-placeholder {
  font-family: 'Poppins';
  line-height: 20px;
  color: rgba(31, 34, 39, 0.7); }

.FieldWrapper_input__1AMcZ::placeholder, .FieldWrapper_input__1AMcZ::-webkit-input-placeholder, .FieldWrapper_input__1AMcZ::-moz-placeholder, .FieldWrapper_input__1AMcZ:-ms-input-placeholder {
  font-family: 'Poppins';
  line-height: 20px;
  color: rgba(31, 34, 39, 0.7); }

.FieldWrapper_error__1FSBM {
  position: absolute;
  top: 0;
  font-size: 9px;
  font-weight: 300;
  letter-spacing: 0.7px;
  color: #e50d36;
  padding: 2px 0 0 5px; }

.FieldWrapper_invalid__1_fpN {
  border-color: #e50d36; }

.FieldWrapper_invalid__1_fpN:focus {
  outline-color: transparent;
  outline-style: none;
  border: solid 2px #e50d36; }

.ContactForm_container__194Gh {
  padding: 0 20px; }

.ContactForm_defaultField__3eVvn {
  border: none;
  margin: 0 0 25px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.Formik__submitButton {
  cursor: pointer;
  width: 90%;
  height: 50px;
  border-radius: 5px;
  background-color: #1a9fdf;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  border: 2px solid #1a9fdf;
  outline: #1a9fdf;
  position: absolute;
  bottom: 2%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .Formik__submitButton:hover {
    background-color: #157eb1;
    border: 2px solid #157eb1;
    transition: all ease-out .8s; }
  .Formik__submitButton:disabled {
    background-color: #989898;
    border: 2px solid #989898;
    outline: #989898; }
    .Formik__submitButton:disabled:hover {
      background-color: #7f7f7f;
      border: 2px solid #7f7f7f; }

.ContactForm_disabled__2Hf70 {
  cursor: unset;
  background-color: #989898;
  border: 2px solid #989898;
  outline: #989898; }
  .ContactForm_disabled__2Hf70:hover {
    background-color: #7f7f7f;
    border: 2px solid #7f7f7f; }

#loader {
  width: 34px;
  height: 34px;
  position: absolute;
  left: 78%; }

@media (min-width: 525px) {
  #loader {
    left: 72%; } }

.PaymentInfo_paymentWrapper__2kyTG {
  color: #818793;
  padding-bottom: 25px; }

.PaymentInfo_paymentDetails__17_d_ {
  color: #1f2227;
  line-height: 1; }

.PaymentInfo_paymentParagraph__2eJQx {
  margin-top: 10px; }

.PaymentInfo_paymentParagraphBold__3HlKc {
  font-weight: 600; }

.PriceInfo_priceInfo__3xYvv {
  padding-top: 15px; }

.PriceInfo_priceWrapper__9M18E {
  line-height: 1; }

.PriceInfo_priceDetails__3BYqo {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }

.PriceInfo_priceTotal__KB1CI {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: #1a9fdf;
  border-top: 1px solid #1a9fdf;
  padding: 15px 0 20px 0; }

.PriceInfo_delivery__2Gem- {
  margin-top: 15px; }
  .PriceInfo_delivery__2Gem- .RadioButtonGroup__label:before {
    content: '';
    background-color: #fff;
    border: 5px solid #fff; }
  .PriceInfo_delivery__2Gem- .RadioButtonGroup__item {
    margin-bottom: 13px;
    line-height: 19px; }

.PriceInfo_priceText__3A7pS {
  margin-top: 10px; }

.PriceInfo_deliveryPrices__3cFLv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .PriceInfo_deliveryPrices__3cFLv span {
    margin-bottom: 13px;
    line-height: 19px;
    text-align: right; }

.PriceInfo_deliveryNote__3mANQ {
  color: #818793;
  font-size: 10px;
  letter-spacing: 1px;
  padding-left: 40px;
  margin-top: -13px;
  margin-bottom: 20px; }

.Checkbox__wrapper {
  position: relative;
  color: #818793;
  margin-top: 20px;
  font-size: 12px;
  line-height: 22px;
  /*Outer box of the fake checkbox*/
  /*Checkmark of the fake checkbox*/ }
  .Checkbox__wrapper input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: auto;
    overflow: hidden;
    width: 1px;
    height: 1px; }
  .Checkbox__wrapper label {
    position: relative;
    display: inline-block;
    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 37px; }
  .Checkbox__wrapper label::before,
  .Checkbox__wrapper label::after {
    position: absolute;
    content: "";
    display: inline-block; }
  .Checkbox__wrapper label::before {
    height: 20px;
    width: 20px;
    border-radius: 5px;
    background-color: #fff;
    border: 2px solid #e0e0e0;
    top: 0px;
    left: 0px; }
  .Checkbox__wrapper label::after {
    height: 6px;
    width: 12px;
    border-left: 3px solid;
    border-bottom: 3px solid;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    color: #1a9fdf;
    left: 5px;
    top: 6px; }

/*Hide the checkmark by default*/
.Checkbox__wrapper input[type="checkbox"] + label::after {
  content: none; }

/*Unhide on the checked state*/
.Checkbox__wrapper input[type="checkbox"]:checked + label::after {
  content: ""; }

/*Adding focus styles on the outer-box of the fake checkbox*/
.Checkbox__wrapper input[type="checkbox"]:focus + label::before {
  box-shadow: 0px 0px 0px 1px #1a9fdf inset; }

.CheckboxError_checkboxError__wo6yX {
  position: absolute;
  font-size: 9px;
  font-weight: 300;
  letter-spacing: 0.7px;
  color: #e50d36;
  padding-left: 37px; }

.Link__anchor {
  color: inherit; }
  .Link__anchor:visited {
    color: inherit; }
  .Link__anchor:focus {
    outline-color: transparent; }

.scrollbar-container {
  position: absolute;
  top: 93px;
  width: 100%;
  height: auto; }

.ps--active-y > .ps__rail-y {
  background-color: #dddbdb;
  border-radius: 3.5px;
  width: 10px; }

.ps__rail-y:hover > .ps__thumb-y {
  right: 0px; }

.Form_container__2DhPQ {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #fff;
  color: #1f2227; }

.Form_header__3sNpY {
  position: relative;
  width: 100%;
  max-width: 505px;
  margin: 0 auto;
  padding: 35px 0 25px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center; }

.Form_heading__3-1z1 {
  font-size: 22px;
  font-weight: 600;
  margin: 0; }

.Form_arrow__15WY- {
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  width: 70px;
  height: 33px;
  position: absolute;
  top: 35px;
  left: 0;
  outline-style: none; }
  .Form_arrow__15WY-::before {
    content: '';
    position: absolute;
    color: #1f2227;
    border: 2px solid #1f2227;
    border-width: 0 0 2px 2px;
    width: 10px;
    height: 10px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: 10px;
    left: 25px; }

.Form_arrow__15WY-:hover::before {
  border: 2px solid #1a9fdf;
  border-width: 0 0 2px 2px;
  -webkit-transform: scale(1.1) rotate(-45deg);
          transform: scale(1.1) rotate(-45deg);
  transition: -webkit-transform ease 0.5;
  transition: transform ease 0.5;
  transition: transform ease 0.5, -webkit-transform ease 0.5; }

.Form_arrow__15WY-:focus {
  outline-color: transparent; }

.Form_form__uz27O {
  max-width: 505px;
  margin: 0 auto;
  position: relative; }

.Form_summaryContainer__3DqET {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #e8e7e7;
  width: 100%;
  font-size: 14px;
  padding: 20px 0 130px 0; }

.Form_summaryContent__2m4cV {
  margin: 0 20px; }
  .Form_summaryContent__2m4cV .InfoBox__container {
    width: 100%;
    padding: 21px 18px 21px 18px; }
  .Form_summaryContent__2m4cV .InfoBox__contentWrapper {
    width: inherit; }
  .Form_summaryContent__2m4cV .InfoBox__column--first {
    width: 70%; }

.Form_summaryHeading__1hrfg {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
  margin: 0; }

@media (min-width: 1240px) {
  .scrollbar-container {
    position: relative;
    top: 0;
    height: 100%; }
  .Form_form__uz27O {
    position: relative;
    height: 1690px; }
  .Form_form__uz27O .Formik__submitButton {
    bottom: 6.5%; }
  .Form_formIncrease__10V7y {
    height: calc(1690px + 440px); }
  .Form_formIncrease__10V7y .Formik__submitButton {
    bottom: 5%; } }

.ResponseHeader_header__3OWvf {
  width: 100%;
  max-width: 505px;
  margin: 0 auto;
  padding: 35px 0 35px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center; }

.ResponseHeader_heading__pZPgk {
  font-size: 22px;
  font-weight: 600;
  margin: 0; }

.ResponseContent_contentDefault__aY7m9 {
  padding-bottom: 25px; }
  .ResponseContent_contentDefault__aY7m9 .Link__anchor {
    color: #1a9fdf;
    text-decoration: none; }

.ResponseContent_contentDefault__aY7m9 {
  font-size: 14px;
  color: #818793; }
  .ResponseContent_contentDefault__aY7m9 p {
    margin: 0 0 25px 0;
    line-height: 20px; }
  .ResponseContent_contentDefault__aY7m9 span {
    white-space: pre-line; }

.ResponseMessage_container__3dV2Q {
  height: 100%;
  max-width: 505px;
  margin: 0 auto;
  background-color: #fff;
  color: #1f2227;
  padding: 0px 25px 35px 35px; }

.ResponseMessage_content__rPn4I {
  font-size: 14px;
  color: #818793; }
  .ResponseMessage_content__rPn4I p {
    margin: 0 0 25px 0;
    line-height: 20px; }
  .ResponseMessage_content__rPn4I span {
    white-space: pre-line; }

.ResponseMessage_contentBigger__1DZtC {
  font-size: 18px; }

.Sidebar_container__1-BQs {
  position: relative;
  width: 100%;
  background-color: #f6f6f6;
  -webkit-order: 1;
          order: 1;
  overflow: hidden; }

.Sidebar_openForm__l_H76 {
  overflow: inherit; }

.Sidebar_form__3SO0l,
.Sidebar_successContainer__3VQ2D {
  height: 100%;
  width: 100%;
  position: absolute;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  transition: -webkit-transform ease-in-out 0.6s;
  transition: transform ease-in-out 0.6s;
  transition: transform ease-in-out 0.6s, -webkit-transform ease-in-out 0.6s; }

.Sidebar_successContainer__3VQ2D {
  display: none;
  top: 0; }

.Sidebar_successContainer__3VQ2D.Sidebar_active__1d_HZ {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  display: block;
  height: 100vh; }

.Sidebar_successContainer__3VQ2D.Sidebar_disactive__3FJiO {
  -webkit-transform: translateY(0%);
          transform: translateY(0%); }

.Sidebar_active__1d_HZ {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  display: block; }

.Sidebar_disactive__3FJiO {
  display: none;
  -webkit-transform: translateY(0%);
          transform: translateY(0%); }

.Sidebar_configurator__3PMoJ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.Sidebar__content {
  font-size: 14px;
  background-color: #fff;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  width: 89%;
  margin: 0 auto;
  padding: 35px 20px 100px 20px; }

.Sidebar__wrapper {
  max-width: 505px;
  margin: 0 auto; }

.Sidebar__heading {
  font-size: 18px;
  line-height: 1.11;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 30px; }

@media (min-width: 1240px) {
  .Sidebar_container__1-BQs {
    height: 668px;
    max-width: 410px;
    -webkit-order: 0;
            order: 0;
    margin: 80px 0 0 30px;
    overflow: hidden;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px; }
  .Sidebar_configurator__3PMoJ {
    -webkit-flex-direction: row;
            flex-direction: row; }
  .Sidebar__wrapper {
    width: unset;
    margin: 0 auto; }
  .Sidebar__content {
    border-top-right-radius: 20px;
    padding: 40px 20px 20px 20px; } }

