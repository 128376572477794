@import '../scss/_variables.scss';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  background-color: $sidebarBackground;
}

@media (min-width: 1240px) {
  .container {
    flex-direction: row;
    overflow-x: hidden;
  }
};