@import '../../../scss/_variables.scss';

.description {
  margin-top: 45px;
}

.nav {
  padding: 20px 0 37px 0;
  border-top: 1px solid $lightGray;
}

.list {
  font-size: 15px;
  font-weight: normal;
  line-height: 1.33;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.item {
  cursor: pointer;
  list-style: none; 
  color: $darkGray;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;

  :hover {
    color: $mainBlue;
    position: relative;
  }
}

.heading {
  font-size: inherit;
  font-weight: normal;
  margin: 0;
}

.active {
  position: relative;
  color: $mainBlue;

  &::after {
    content: '';
    position: absolute;
    width: 142.9%;
    height: 2px;
    left: -22.45%;
    background-color: $mainBlue;
    bottom: -17px;
  }
}

.content {
  :global(.InfoBox__container) {
    padding: 25px 21px 21px 21px;
    // margin-bottom: 20px;
  }
}
