@import '../../../scss/_variables.scss';

.container {
  height: 100%;
  max-width: $maxContentWidth;
  margin: 0 auto;
  background-color: $white;
  color: $textColour;
  padding: 0px 25px 35px 35px;
}

.content {
  font-size: 14px;
  color: $darkGray;

  & p {
    margin: 0 0 25px 0;
    line-height: 20px;
  }

  & span {
    white-space: pre-line;
  }
}

.contentBigger {
  font-size: 18px;
}