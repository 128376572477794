:global(.Link__anchor) {
  color: inherit;

  &:visited {
    color: inherit;
  }

  &:focus {
    outline-color: transparent;
  }
}