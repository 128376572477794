* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  outline-color: transparent;
}

p {
  margin: 0;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Amatic';
  src: url('./assets/fonts/AmaticSC.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'cmu';
  src: url('./assets/fonts/cmu-typewriter.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Havana';
  src: url('./assets/fonts/Havana.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Pecita';
  src: url('./assets/fonts/Pecita.woff2') format('woff2'),
        url('./assets/fonts/Pecita.woff') format('woff'), 
        url('./assets/fonts/Pecita.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./assets/fonts/Quicksand.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Shadows';
  src: url('./assets/fonts/Shadows.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'LearningCurve';
  src: url('./assets/fonts/LearningCurve.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Tamoro';
  src: url('./assets/fonts/Tamoro.ttf') format('truetype');
  font-weight: normal;
}