@import '../../scss/_variables.scss';
@import '../../scss/mixin';

.container{
  height: 74%;
  width: 100%;
  order: 0;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  overflow-y: hidden;
  -webkit-transform: translate3D(0, 0, 0);
  transform: translate3D(0, 0, 0);
}

.text {
  max-width: 94%;
  max-height: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  transform: translateY(17vh);
  overflow-wrap: break-word;
  text-align: center;
  color: $white;
  padding: 10px;
  line-height: 44px;
}

#neon {
  max-width: 100%;
}

.classicRed, .classicBlue {
  color: rgba($white, 0.5);
}

.superOrange {
  color: $superOrangeBorder;
}

.novialGold {
  color: $novialGoldOff;
}

.emeraldGreen {
  color: $emeraldGreenOff;
}

.cobaltBlue {
  color: $cobaltBlueOff;
}

.rubyRed {
  color: $rubyRedBorder;
}

.small {
  font-size: 23px;
}

.big {
  font-size: 35px;
}

.cloud {
  border-radius: 60px;
}

.acrylic {
  box-shadow: 3px 3px 10px rgba($black, 0.6), 1px 1px 1px rgba($white, 0.4) inset, -1px -1px 1px rgba($white, 0.2) inset, 10px 10px 10px rgba($black, 0.1) inset, -10px -10px 10px rgba($black, 0.1) inset;
  background: linear-gradient(1deg, rgba($white, 0) 43%, rgba($white, 0.11) 45%);

  &_transparent {
    background-color: transparent;
  }
  
  &_white {
    background-color: rgba($white, 0.75);
  }
  
  &_black {
    background-color: rgba($black, 0.75);
  }
}

.aluminium {
  box-shadow: 3px 3px 10px rgba($black, 0.6), 1px 1px 1px rgba($white, 0.4) inset, -1px -1px 1px rgba($white, 0.2) inset, 10px 10px 30px rgba($black, 0.1) inset, -10px -10px 30px rgba($black, 0.1) inset;

  &_alWhite {
    background-color: $alWhite;
  }
  &_alBlack {
    background-color: $alBlack;
  }
  &_alPure {
    background-color: $alPure;
  }
  &_alYellow {
    background-color: $alYellow;
  }
  &_alRed {
    background-color: $alRed;
  }
  &_alBlue {
    background-color: $alBlue;
  }
  &_alGreen {
    background-color: $alGreen;
  }
  &_alPink {
    background-color: $alPink;
  }
  &_alGray {
    background-color: $alGray;
  }
  &_alRaw {
    background-color: $alRaw;
  }
}

.wood {
  background-image: url('../../assets/images/textures_wood.png');
  background-size: 145%;
}
.noLight {
  .classicRed {
    @include neon($classicRed, $classicRedBorder);
  }

  .classicBlue {
    @include neon($classicBlue, $classicBlueBorder);
  }

  .superBlue {
    @include neon($white, $superBlue);
  }

  .superGreen {
    @include neon($white, $superGreen);
  }

  .superPink {
    @include neon($white, $superPink);
  }

  .superPurple {
    @include neon($white, $superPurple);
  }

  .superOrange {
    @include neon($superOrange, $superOrangeBorder);
  }

  .warmWhite {
    @include neon($white, $warmWhite);
  }

  .coldWhite {
    @include neon($white, $coldWhite);
  }

  .novialGold {
    @include neon($white, $novialGold);
  }

  .emeraldGreen {
    @include neon($white, $emeraldGreen);
  }

  .turqouise {
    @include neon($white, $turqouise);
  }

  .cobaltBlue {
    @include neon($white, $cobaltBlue);
  }

  .rubyRed {
    @include neon($rubyRed, $rubyRedBorder);
  }
}

@media (min-width: 640px) { 
  .container {
    height: 74vh; 
  }

  .text {
    transform: translate(10%, 11vh);
    max-width: 70%;
  }
}

@media (min-width: 768px) {
  .container {
    height: 78vh; 
  }

  .text {
    max-width: 94%;
    transform: translateY(18vh);
    line-height: 50px;
    padding: 20px;
  }

  .small {
    font-size: 33px;
  }
  
  .big {
    font-size: 50px;
    line-height: 64px;
  }
}

@media (min-width: 1024px) {
  .text {
    top: 0;
    left: 57%;
    transform: translate(-55%, 14vh);
  }
}

@media (min-width: 1240px) {
  .container {
    height: 98%; 
  }

  .text {
    max-width: 45%;
    left: 64%;
    transform: translate(-65%, 20vh);
  }
}

@media (min-width: 1440px) {
  .text {
    left: 61%;
    transform: translate(-62%, 20vh);

  }
}