.list {
  width: 100%;  
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 48px 0;
}

.item {
  padding-right: 10px;
}
