@import '../../scss/_variables.scss';
@import '../../scss/mixin';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  @include border-radius('bottomRight', $sidebarRadius);
  @include border-radius('bottomLeft', $sidebarRadius);
}

.backgroundImage {
  position: absolute;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 70%;
  background-image: url('../../assets/images/bg_livingroom.jpg');
}

:global(.backgroundImage-enter) {
  opacity: 0.01;
}

:global(.backgroundImage-enter.backgroundImage-enter-active) {
  opacity: 1;
  transition: opacity .5s ease-in-out;
}

:global(.backgroundImage-leave) {
  opacity: 1;
}

:global(.backgroundImage-leave.backgroundImage-leave-active) {
  opacity: 0.01;
  transition: opacity .5s ease-in-out;
}

.wrapper {
  height: 87vh;
  color: white;
}

.svgCable {
  position: absolute;
  height: 100%;
  width: 1%;
  z-index: 0;
  top: 0;
  left: 47%;
  padding-top: 28vh;
  padding-bottom: 37vh;
  display: flex;
}

:global(#bg_cable) {
  height: 100%;
  // position: absolute;
    top: 0;
    padding-top: inherit;
    padding-bottom: inherit;
}

.svgCable_white {
  .svgCableDefault > :global(#bg_cable) g path {
    fill: $lightGray;
    fill-opacity: 0.6;
  }
}

.svgCableDefault {
  height: 98%;
  transform: translate(-43%, 2%);

  :global(#bg_cable) {
    height: 100%;   
  }

  :global(#bg_cable) g path {
    fill-opacity: 1;
    fill: $cableShadow;
  }
}

.svgCableShadow {
  height: 98%;
  transform: translate(7%, 3%);

  :global(#bg_cable) {
    height: 100%;
  }

  :global(#bg_cable) g path {
    fill-opacity: 0.3;
    fill: $cableShadow;
  }
}

.svgCable_livingroom {
  :global(#bg_cable) {
    transform: scaleY(1);
    transition: transform 0.2s linear;
  }
}

.svgCable_bedroom {
  :global(#bg_cable) {
    transform-origin: left 0px;
    transform: scaleY(.81);
    transition: transform 0.2s linear;
  }
}

.svgCable_kidsroom {
  :global(#bg_cable) {
    transform-origin: left -15px;
    transform: scaleY(1.3);
    transition: transform 0.2s linear;
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.5);
  }
}

.lightButton {
  position: absolute;
  top: 0;
  right: 0;
  margin: 24px 20px 0 0;
  width: 16px;
  height: 20px;
}

.list {
  height: 100%;
  margin: 0 auto;
  padding: 0;
  padding-bottom: 18px;
  font-size: 15px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.item {
  padding: 0px 15px;
  cursor: pointer;
  position: relative;
  transform: scale(1);
  transition: transform ease-in-out .2s;

  &:hover {
    transform: scale(1.1);
    // transition: transform ease-in .2s;
    // backface-visibility: hidden;
  };
}

.active {
  top: 0px;

  &::after {
    content: '';
    position: absolute;
    background-color: $sidebarBackground;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 21px;
    height: 21px;
    border-radius: 50%;
  }

  &:hover {
    transform: none;
  }
}

@media (min-width: 640px) {
  .svgCable {
    left: 55%;
    transform: translateX(-55%);
  }
}

@media (min-width: 768px) {
  .container {
    background-position-x: 70%;
  }

  .wrapper {
    height: 90vh;
  }

  .svgCable {
    padding-bottom: 38vh;
    left: 50%;
    transform: translateX(-50%);
  }

  .svgCable_livingroom {
    :global(#bg_cable) {
      transform: scaleY(1);
      transition: transform 0.2s linear;
    }
  }

  .svgCable_bedroom {
    :global(#bg_cable) {
      transform-origin: left -10px;
      transform: scaleY(.82);
      transition: transform 0.2s linear;
    }
  }

  .svgCable_kidsroom {
    :global(#bg_cable) {
      transform-origin: left 15px;
      transform: scaleY(1.32);
      transition: transform 0.2s linear;
    }
  }

  .lightButton {
    margin: 28px 35px 0 0;
    width: 100px;
    height: 50px;
  }
}

@media (min-width: 1024px) {
  .svgCable {
    left: 55%;
    transform: translateX(-55%);
  }
}

@media (min-width: 1240px) {
  .container {
    background-position-x: 55%;
    background-size: cover;
    min-height: 755px; //height of Sidebar & Header
    position: absolute;
    margin-bottom: 0;
    border-radius: unset;
  }

  .wrapper {
    position: relative;
    top: 0;
    height: 100%;
  }

  .list {
    left: 440px;
    width: calc(100% - 440px);
    margin: 0 0 0 auto;
    align-items: flex-start;
    padding-top: 50px;
  }

  .item {
    padding: 10px 15px;
  }

  .active {
    &::after {
      content: '';
      background-color: $white;
    }
  }

  .lightButton {
    margin-right: 4%;
  }

  .svgCable {
    padding-bottom: 42.6vh;
    left: 62%;
    transform: translateX(-62%);
  }

  .svgCable_livingroom {
    :global(#bg_cable) {
      transform: scaleY(1);
      transition: transform 0.2s linear;
    }
  }

  .svgCable_bedroom {
    :global(#bg_cable) {
      transform-origin: left 20px;
      transform: scaleY(.83);
      transition: transform 0.2s linear;
    }
  }

  .svgCable_kidsroom {
    :global(#bg_cable) {
      transform-origin: left -35px;
      transform: scaleY(1.23);
      transition: transform 0.2s linear;
    }
  }

  .active {
    top: -25px;

    &::after {
      content: '';
      position: absolute;
      background-color: $white;
      bottom: -20px;
      left: 45.3%;
      width: 9px;
      height: 9px;
      border-radius: 50%;
    }
  }
}

@media (min-width: 1440px) {
  .svgCable {
    left: 60%;
    transform: translateX(-60%);
  }

  .lightButton {
    margin-right: 6%;
  }
}

@media (min-width: 1680px) { 
  .svgCable {
    padding-bottom: 41.8vh;
  }
}
