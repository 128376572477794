@import '../../../../scss/_variables.scss';

.fieldWrapper {
  margin-bottom: 10px;
  position: relative;
}
.input {
  font-family: 'Poppins';
  width: 100%;
  height: 50px;
  padding: 18px 10px;
  border-radius: 5px;
  border: solid 2px rgba($lightGray, 0.6);
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  color: $textColour;
}

.input:focus, .input:active {
  outline-color: transparent;
  outline-style: none;
  border: solid 2px $mainBlue;
}

.input::placeholder, .input::-webkit-input-placeholder, .input::-moz-placeholder, .input:-ms-input-placeholder {
  font-family: 'Poppins';
  line-height: 20px;
  color: $placeholderColour;
}

.error {
  position: absolute;
  top: 0;
  font-size: 9px;
  font-weight: 300;
  letter-spacing: 0.7px;
  color: $alertColour;
  padding: 2px 0 0 5px;
}

.invalid {
  border-color: $alertColour;
}

.invalid:focus {
  outline-color: transparent;
  outline-style: none;
  border: solid 2px $alertColour;
}