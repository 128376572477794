.list {
	width: 100%;
	max-width: 325px;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 48px;
}

.item {
	padding: 5px;
}

.item:nth-child(5n + 1) {
	padding-left: 0;
}

.item:nth-child(5n) {
	padding-right: 0;
}

@media (min-width: 360px) {
	.item {
		padding: 10px;
	}
}

@media (min-width: 520px) {
	.list {
		max-width: 425px;
	}

	.item:nth-child(n) {
		padding: 10px;
	}

	.item:nth-child(7n + 1) {
		padding-left: 0;
	}
}

@media (min-width: 1240px) {
	.item:nth-child(n) {
		padding: 10px;
	}

	.item:nth-child(5n + 1) {
		padding-left: 0;
	}
}