@import '../../scss/_variables';

:global(.Checkbox__wrapper) {
  position: relative;
  color: $darkGray;
  margin-top: 20px;
  font-size: 12px;
  line-height: 22px;

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: auto;
    overflow: hidden;
    width: 1px;
    height: 1px;
  }

  label {
    position: relative;
    display: inline-block;
    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 37px;
  }

  label::before,
  label::after {
    position: absolute;
    content: "";
    display: inline-block;
  }

  /*Outer box of the fake checkbox*/
  label::before {
    height: 20px;
    width: 20px;
    border-radius: 5px;
    background-color: $white;
    border: 2px solid $lightGray;
    top: 0px;
    left: 0px;
  }

  /*Checkmark of the fake checkbox*/
  label::after {
    height: 6px;
    width: 12px;
    border-left: 3px solid;
    border-bottom: 3px solid;
    transform: rotate(-45deg);
    color: $mainBlue;
    left: 5px;
    top: 6px;
  }
}

/*Hide the checkmark by default*/
:global(.Checkbox__wrapper) input[type="checkbox"]+label::after {
  content: none;
}

/*Unhide on the checked state*/
:global(.Checkbox__wrapper) input[type="checkbox"]:checked+label::after {
  content: "";
}

/*Adding focus styles on the outer-box of the fake checkbox*/
:global(.Checkbox__wrapper) input[type="checkbox"]:focus+label::before {
  // outline: $mainBlue auto 1px;
  box-shadow: 0px 0px 0px 1px $mainBlue inset;
}