@import '../../../scss/_variables.scss';
@import '../../../scss/mixin';

:global(.scrollbar-container) {
  position: absolute;
  top: 93px;
  width: 100%;
  height: auto;
}

:global(.ps--active-y > .ps__rail-y) {
  background-color: #dddbdb;
  border-radius: 3.5px;
  width: 10px;
}

:global(.ps__rail-y:hover > .ps__thumb-y) {
  right: 0px;
}

.container {
  height: 100%;
  width: 100%;
  position: absolute; //
  background-color: $white;
  color: $textColour;
}

.header {
  position: relative;
  width: 100%;
  max-width: $maxContentWidth;
  margin: 0 auto;
  padding: 35px 0 25px 0; 
  display: flex;
  justify-content: center;
  text-align: center;
}

.heading {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}

.arrow {
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  width: 70px;
  height: 33px;
  position: absolute;
  top: 35px;
  left: 0;
  outline-style: none;

  &::before {
    content: '';
    position: absolute;
    color: $textColour;
    border: 2px solid $textColour;
    border-width: 0 0 2px 2px;
    width: 10px;
    height: 10px;
    transform: rotate(-45deg);
    top: 10px;
    left: 25px;
  }
}

.arrow:hover{
  &::before  {
    border: 2px solid $mainBlue;
    border-width: 0 0 2px 2px;
    transform: scale(1.1) rotate(-45deg);
    transition: transform ease 0.5;
  }
}

.arrow:focus {
  outline-color: transparent;
}

.form {
  max-width: $maxContentWidth;
  margin: 0 auto;
  position: relative;
}

.summaryContainer {
  @include border-radius('topLeft', $sidebarRadius);
  @include border-radius('topRight', $sidebarRadius);
  background-color: $summaryBackground;
  width: 100%;
  font-size: 14px;
  padding: 20px 0 130px 0;
}

.summaryContent {
  margin: 0 20px;

  :global(.InfoBox__container) {
    width: 100%;
    padding: 21px 18px 21px 18px;
  }

  :global(.InfoBox__contentWrapper) {
    width: inherit;
  }

  :global(.InfoBox__column--first) {
    width: 70%;
  }
}

.summaryHeading {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
  margin: 0;
}

@media (min-width: 1240px) {
  :global(.scrollbar-container) {
    position: relative;
    top: 0;
    height: 100%;
  }

  .form {
    position: relative;
    height: 1690px;
  }

  :local(.form) {
    :global(.Formik__submitButton){
      bottom: 6.5%;
    }
  }

  .formIncrease {
    height: calc(1690px + 440px);
  }

  :local(.formIncrease) {
    :global(.Formik__submitButton){
      bottom: 5%;
    }
  }
}


