@import '../../../../scss/_variables.scss';

.container {
  padding: 0 20px;
}

.defaultField {
  border: none;
  margin: 0 0 25px 0;
  display: flex;
  flex-direction: column;
}

:global(.Formik__submitButton) {
  cursor: pointer;
  width: 90%;
  height: 50px;
  border-radius: $commonRadius;
  background-color: $mainBlue;
  color: $white;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  border: 2px solid $mainBlue;
  outline: $mainBlue;
  position: absolute;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: darken($mainBlue, 10%);
    border: 2px solid darken($mainBlue, 10%);
    transition: all ease-out .8s;
  }

  &:disabled {
    background-color: $buttonBackground;
    border: 2px solid $buttonBackground;
    outline: $buttonBackground;

    &:hover {
      background-color: darken($buttonBackground, 10%);
      border: 2px solid darken($buttonBackground, 10%);
    }
  }
}

.disabled {
  cursor: unset;
  background-color: $buttonBackground;
  border: 2px solid $buttonBackground;
  outline: $buttonBackground;

  &:hover {
    background-color: darken($buttonBackground, 10%);
    border: 2px solid darken($buttonBackground, 10%);
  }
}

:global(#loader) {
  width: 34px;
  height: 34px;
  position: absolute;
  left: 78%;
}

@media (min-width: 525px) {
  :global(#loader) {
    left: 72%;
  }
}