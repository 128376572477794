@mixin border-radius($corner, $radius) {
  @if $corner == 'topLeft' {
    border-top-left-radius: $radius;
  }
  @else if $corner == 'topRight' {
    border-top-right-radius: $radius;
  }
  @else if $corner == 'bottomRight' {
    border-bottom-right-radius: $radius;
  }
  @else {
    border-bottom-left-radius: $radius;
  }
}

@mixin neon($color, $shadow) {
  color: $color;
  text-shadow:0 0 1px $shadow, 0 0 30px $shadow, 3px 3px 30px rgba(0, 0, 0, 0.05), 0 0 8px $shadow, 0 0 15px $shadow,  0 0 30px $shadow, 0 0 50px $shadow, 0 0 50px $shadow, 0 0 80px $shadow, 0 0 200px $shadow, 0 0 250px $shadow;
}

@mixin button-shadow() {
  box-shadow: -1px 1.7px 10px 0 rgba(37, 37, 37, 0.25);
}