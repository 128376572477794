@import '../../scss/_variables.scss';
@import '../../scss/_mixin.scss';

.default {
  cursor: pointer;
  display: flex;
  outline: none;
  border-radius: $commonRadius;
  border: 0 transparent;
  width: 40px;
  height: 40px;
}

.default:hover {
  box-shadow: -1px 1.7px 6px 0px rgba(37, 37, 37, 0.4);
  transition: box-shadow ease-in-out .3s;
}

.selected {
  box-shadow: -1px 1.7px 8px 0px rgba(37, 37, 37, 0.5);
}

.colour, .cable, .acrylic, .aluminium, .wood {
  composes: default;
}

:local(.colour) {
  :global &.warmWhite {
      background-color: $warmWhite;
      border: solid 2px $lightGray;
    }
  
  :global &.coldWhite {
    background-color: $coldWhite;
    border: solid 2px $lightGray;
  }
}


:local(.cable), :local(.acrylic), :local(.aluminium) {
  :global &.transparent {
    background-color: $transparent;
    border: solid 2px $lightGray;
    }
  
  :global &.white {
    background-color: $white;
    border: solid 2px $lightGray;
  }

  :global &.alWhite {
    background-color: $alWhite;
    border: solid 2px $lightGray;
  }
}
 
