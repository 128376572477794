@import '../../scss/_variables.scss';

.default {
  cursor: pointer;
  width: 100%;
  height: 50px;
  border-radius: $commonRadius;
  background-color: $mainBlue;
  color: $white;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  border: 2px solid $mainBlue;
  outline: $mainBlue;
  margin-top: 10px;

  &:hover {
    background-color: darken($mainBlue, 10%);
    border: 2px solid darken($mainBlue, 10%);
    transition: all ease-out .8s;
  }
}

.disabled, .default:disabled {
  background-color: $buttonBackground;
  border: 2px solid $buttonBackground;
  outline: $buttonBackground;
  cursor: unset;

  &:hover {
    background-color: darken($buttonBackground, 10%);
    border: 2px solid darken($buttonBackground, 10%);
  }
}