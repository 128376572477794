@import '../../../scss/_variables.scss';

.input {
  font-family: 'Poppins';
  color: $textColour;
  width: 92%;
  resize: none;
  font-size: 14px;
  border: 2px solid $lightGray;
  border-radius: $commonRadius;
  padding: 20px 10px 10px 10px;
  outline-style: none; 

  &:focus, &:active {
    border: 2px solid rgba($mainBlue, 0.7);
    outline-color: transparent;
  }
}

.input::placeholder, .input::-webkit-input-placeholder, .input::-moz-placeholder, .input:-ms-input-placeholder {
  font-family: 'Poppins';
  line-height: 20px;
  color: $placeholderColour;
}

.description {
  font-size: 12px;
  color: $darkGray;
  letter-spacing: 0.7px;
  margin: 0 0 96px 0;  
  white-space: pre-line;
}