@import '../../../scss/_variables.scss';
@import '../../../scss/mixin';

.container {
  background-color: $secondarySidebarBackground;
  width: 100%;
  height: 53px;
  border-radius: 26.5px;
  margin: 15px 0;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  height: 31px;
  max-width: 37px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.list {
  display: flex;
  justify-content: space-around;
  list-style: none;
  height: 100%;
  padding: 12px 0;
}

.item {
  svg {
    height: 31px;
    max-width: 100%;
  }
}

:local(.item) {
  :global .tabicon {
    fill: $white;
  }
}

:local(.item:hover) {
  :global .tabicon {
    fill: $mainBlue;
    transition: fill ease-in-out .5s;
  }
}

:local(.active) {
  :global .tabicon {
    fill: $mainBlue;
  }
}


@media (min-width: 768px) {
  .container {
    width: 93%;
    margin: 15px auto;
  }

  .list {
    justify-content: space-evenly;
  }
}

@media (min-width: 1240px) {
  .container {
    background-color: $sidebarBackground;
    width: 79px;
    height: unset;
    border-radius: 0;
    margin: 0;
    @include border-radius('topLeft', $sidebarRadius);
  }
  
  .list {
    flex-direction: column;
    width: inherit;
    padding: 35px 0 25px 0;
  }

  .item {
    box-sizing: content-box;
    display: block;
    padding: 20px 10px;
    margin: 0 auto;
  }

  :local(.item) {
    :global .tabicon {
      fill: #818793;
    }
  }

  :local(.active) {
    :global .tabicon {
      fill: $mainBlue;
    }
  }
}