@import '../../scss/_variables.scss';
@import '../../scss/_mixin.scss';

.default {
  cursor: pointer;
  outline: transparent;
  border-radius: $commonRadius;
  border: solid 2px $lightGray;
  background-color: transparent;
  height: 85px;
  width: 112px;
}

.default:hover {
  @include button-shadow();
  transition: box-shadow ease-in-out .3s;
}

.selected {
  @include button-shadow();
}

.font {
  composes: default;
  font-size: 18px;
  letter-spacing: 1.8px;
}

.size {
  composes: default;
  font-size: 16px;
  font-weight: 600;
}

@media (min-width: 365px) {
  .font {
    width: 135px;
    font-size: 19px;
  }
};