@import '../../../../scss/_variables.scss';

.priceInfo {
  padding-top: 15px;
}

.priceWrapper {
  line-height: 1;
}

.priceDetails {
  composes: priceWrapper;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.priceTotal {
  composes: priceDetails;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: $mainBlue;
  border-top: 1px solid $mainBlue;
  padding: 15px 0 20px 0;
}

.delivery {
  composes: priceDetails;
  margin-top: 15px;

  :global(.RadioButtonGroup__label:before) {
    content: '';
    background-color: $white;
    border: 5px solid $white;
  }

  :global(.RadioButtonGroup__item) {
    margin-bottom: $deliveryMargin;
    line-height: 19px;
  }
}

.priceText {
  margin-top: 10px;
}

.deliveryPrices {
  display: flex;
  flex-direction: column;

  span {
    margin-bottom: $deliveryMargin;
    line-height: 19px;
    text-align: right;
  }
}

.deliveryNote {
  color: #818793;
  font-size: 10px;
  letter-spacing: 1px;
  padding-left: 40px;
  margin-top: -13px;
  margin-bottom: 20px;
}