//main colors:
$sidebarBackground: #f6f6f6;
$secondarySidebarBackground: #bbbbbb;
$summaryBackground: #e8e7e7;
$textColour: #1f2227;
$placeholderColour: rgba(31, 34, 39, 0.7);
$lightGray: #e0e0e0;
$darkGray: #818793;
$buttonBackground: #989898;
$mainBlue: #1a9fdf;
$cableShadow: #383b3f;
$alertColour: #e50d36;
$alertBackground: #e6838f;

//others
$sidebarRadius: 20px;
$commonRadius: 5px;
$deliveryMargin: 13px;
//be aware! width calc of .InfoBox__container depends on maxContentWidth value
$maxContentWidth: 505px;

//
$black: #000;
$white: #fff;
$transparent: #efefef;


//aluminium RAL palette
$alBlack: #0A0A0A; //9005
$alWhite: #F6F6F6; //9016
$alPure: #8F8F8F; //9007
$alYellow: #FAD201; //1023
$alRed: #CC0605; //3020
$alBlue: #2270B3; //5015
$alGreen: #57A63A; //6018
$alPink: #D82E6C; //4010
$alGray: #2A3133; //7016
$alRaw:  #A5A5A5; //9006


//neon colors:
$warmWhite: #FEF3E5;
$coldWhite: #F5FCFE;
$warmWhite: #FEF3E5;
$novialGold: #FBAD00;
$novialGoldOff: #F6EB47;
$emeraldGreen: #43FD2B;
$emeraldGreenOff: #20893E;
$rubyRed: #F94659;
$rubyRedBorder: #CF241B;
$turqouise: #42F7FE;
$cobaltBlue: #132AFF;
$cobaltBlueOff: #2917A6;
$superBlue: #74A8FE;
$superGreen: #43FE9C;
$superPurple: #FA8BEA;
$superPink: #FA88AA;
$superOrange: #FEEF33;
$superOrangeBorder: #F96929;
$classicBlue: #B0F2FE;
$classicBlueBorder: #2594BF;
$classicRed: #FCC375;
$classicRedBorder: #F8110B;

//color variables for JS css modules
:export {
  //acrylic
  black: #000;
  white: #fff;
  transparent: #efefef;

  //aluminium
  alBlack: #0A0A0A; //9005
  alWhite: #F6F6F6; //9016
  alPure: #8F8F8F; //9007
  alYellow: #FAD201; //1023
  alRed: #CC0605; //3020
  alBlue: #2270B3; //5015
  alGreen: #57A63A; //6018
  alPink: #D82E6C; //4010
  alGray: #2A3133; //7016
  alRaw:  #A5A5A5; //9006

  //neon colors:
  warmWhite: #FEF3E5;
  coldWhite: #F5FCFE;
  novialGold: #FBAD00;
  emeraldGreen: #43FD2B;
  rubyRed: #F94659;
  turqouise: #42F7FE;
  cobaltBlue: #132AFF;
  cobaltBlueOff: #2917A6;
  superBlue: #74A8FE;
  superGreen: #43FE9C;
  superPurple: #FA8BEA;
  superPink: #FA88AA;
  superOrange: #F96929;
  classicBlue: #B0F2FE;
  classicRed: #F8110B;
  pine: #f1d7c0;
}