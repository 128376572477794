@import '../../../../scss/_variables.scss';

:local(.contentDefault) {
  padding-bottom: 25px;

  :global .Link__anchor {
    color: $mainBlue;
    text-decoration: none;
  }
}

.contentDefault {
  font-size: 14px;
  color: $darkGray;

  & p {
    margin: 0 0 25px 0;
    line-height: 20px;
  }

  & span {
    white-space: pre-line;
  }
}