@import '../../../../scss/_variables.scss';

.header {
  width: 100%;
  max-width: $maxContentWidth;
  margin: 0 auto;
  padding: 35px 0 35px 0;
  display: flex;
  justify-content: center;
  text-align: center;
}

.heading {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}