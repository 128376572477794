@import '../../scss/_variables.scss';

.container {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0 transparent;
  padding: 0;
  margin: 0 0 0 auto;
  text-align: center;
  max-height: 52px;
  outline-color: transparent;
  outline-style: none;
  cursor: pointer;

  & > svg {
    max-width: 25px;
    width: 100%;
    // height: 100%;
  }

  :global svg{
    transform: scale(1);
    transition: transform ease-in-out .2s;
  }

  .description {
    transform: scale(1);
    transition: transform ease-in-out .2s;
  }
}

:local(.container:hover) {
  :global svg{
    transform: scale(1.05);
  }

  .description {
    transform: scale(1.05);
  }
}



.description {
  display: none;
  color: rgba($white, 0.5);
  margin-top: 10px;
  font-size: 15px;
}

@media (min-width: 768px) { 
  .container {
    width: 90px;

    & > svg {
      width: 21px;
      height: 25px;
    }
  }

  .description {
    display: inline;
  }
}