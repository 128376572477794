@import '../../../../scss/_variables.scss';

.paymentWrapper {
  color: $darkGray;
  padding-bottom: 25px;
}

.paymentDetails {
  color: $textColour;
  line-height: 1;
}

.paymentParagraph {
  margin-top: 10px;
}

.paymentParagraphBold {
  composes: paymentParagraph;
  font-weight: 600;
}