@import '../../scss/_variables.scss';

.container {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  margin: 17px 0 0 55px;
  width: 42px;
  height: 35px;
}

.heading {
  margin: 0;
}

.logo {
  position: relative;
  max-width: 100%;

  &::before {
    content: '';
    position: absolute;
    color: $white;
    border: 2px solid $white;
    border-width: 0 0 3px 3px;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    left: -30px;
    top: 10px;
  }

  &:focus {
    color: inherit;
    outline-color: transparent;
  }

  &:active {
    color: inherit;
    text-decoration: none;
  }
}

.logoImage {
  max-width: 55px;
}

@media (min-width: 768px) {
  .container {
    margin: 19px 0 15px 128px;
    width: 55px;
    height: 46px;
}

  .logo::before {
    width: 18px;
    height: 18px;
    left: -50px;
    top: 2px;
  }
}

