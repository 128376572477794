@import '../../scss/_variables';

.list {
  margin: 0;
  padding-left: 40px;
}

:global(.RadioButtonGroup__label) {
  position: relative;
}

:global(.RadioButtonGroup__item) {
  list-style: none;
  margin-bottom: 19px;
  line-height: 19px;

  input[type=radio]:checked + label:before {
    background-color: $mainBlue;
  }
}

.input {
  position: absolute;
    top: auto;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE 6/7 */
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    white-space: nowrap;
    outline: transparent;
}

.input, :global(.RadioButtonGroup__label:before) {
  content: '';
  background: $white;
  border-radius: 50%;
  background-color: $lightGray;
  border: 5px solid $lightGray;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 19px;
  height: 19px;
  margin-left: -29px;
  cursor: pointer;
  text-align: center;
  transition: all .1s ease-out;
}







