@import '../../scss/_variables.scss';
@import '../../scss/mixin';


.container {
  position: relative;
  width: 100%;
  background-color: $sidebarBackground;
  order: 1;
  overflow: hidden; //
}

.openForm {
  overflow: inherit;
}

.form,
.successContainer {
  height: 100%;
  width: 100%;
  position: absolute;
  transform: translateY(0%);
  transition: transform ease-in-out 0.6s;
}

.successContainer {
  display: none;
  top: 0;
}

.successContainer.active {
  transform: translateY(0%);
  display: block;
  height: 100vh;
}

.successContainer.disactive {
  // display: none;
  // transition: transform ease-in-out 0.6s;
  transform: translateY(0%);
}

.active {
  transform: translateY(-100%);
  display: block;
}

.disactive {
  display: none;
  transform: translateY(0%);
}

.configurator {
  display: flex;
  flex-direction: column;
}

:global {
  .Sidebar__content {
    font-size: 14px;
    background-color: $white;
    flex-grow: 1;
    width: 89%;
    margin: 0 auto;
    padding: 35px 20px 100px 20px;
  }

  .Sidebar__wrapper {
    max-width: $maxContentWidth;
    margin: 0 auto;
  }

  .Sidebar__heading {
    font-size: 18px;
    line-height: 1.11;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 30px;
  }
}


@media (min-width: 1240px) {
  .container {
    height: 668px;
    max-width: 410px;
    order: 0;
    margin: 80px 0 0 30px;
    overflow: hidden; //

    @include border-radius('topRight', $sidebarRadius);
    @include border-radius('topLeft', $sidebarRadius);
    @include border-radius('bottomRight', $sidebarRadius);
    @include border-radius('bottomLeft', $sidebarRadius);
  }

  .configurator {
    flex-direction: row;
  }

  :global {
    .Sidebar__wrapper {
      width: unset;
      margin: 0 auto;
    }

    .Sidebar__content {
      @include border-radius('topRight', $sidebarRadius);
      padding: 40px 20px 20px 20px;
    }
  }
}