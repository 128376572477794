@import './scss/_variables.scss';

.App {
  background-color: $white;
  height: 100%;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  color: $textColour;
}

@media (min-width: 1240px) {
  .App {
    height: 100vh;
  }
}

input[type], textarea {
  appearance: none;
}

input[type='text']:focus,
input[type='number']:focus,
textarea:focus {
  font-size: 14px;
}

/* disable auto-zoom on iphone input field focus */
@media screen and (-webkit-min-device-pixel-ratio:0) {

	select:focus,
	textarea:focus,
	input:focus {
		font-size: 14px;
	}
}
