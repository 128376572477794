.list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 20px 0;
  max-width: 350px;
}

.item {
  padding: 10px;
}

.item:nth-child(4n + 1) {
  padding-left: 15px;
}

.item:nth-child(4n) {
  padding-right: 0;
}

@media (min-width: 380px) {
  .item:nth-child(n) {
      padding: 10px;
  }

  .item:nth-child(5n + 1) {
    padding-left: 15px;
  }
  
  .item:nth-child(5n) {
    padding-right: 0;
  }
};

@media (min-width: 525px) {
  .list {
    max-width: 425px;
  }

  .item:nth-child(n) {
      padding: 10px;
  }

  .item:nth-child(7n + 1) {
      padding-left: 15px;
  }
}

@media (min-width: 600px) {
  .list {
    max-width: unset;
  }

  .item:nth-child(n) {
      padding: 10px;
  }

  .item:nth-child(8n + 1) {
      padding-left: 15px;
  }
}

@media (min-width: 1240px) {
  .item:nth-child(n) {
      padding: 10px;
  }

  .item:nth-child(5n) {
      padding-right: 0px;
  }
}